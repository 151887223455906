import type { ParentProps } from "solid-js";
import { Meta, Title } from "solid-start";

export function SiteTitle(props: ParentProps) {
  return (
    <>
      <Title>{props.children} | coverletter.app</Title>
      <Meta
        property="og:title"
        content={`${props.children} - coverletter.app`}
      />
    </>
  );
}
