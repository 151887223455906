import { init } from "@sentry/browser";
import { StartClient, mount } from "solid-start/entry-client";

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: import.meta.env.DEV,
  environment: import.meta.env.PROD ? "production" : "development",
  release: __COMMIT_HASH__,
});

mount(() => <StartClient />, document);
