import { Meta } from "solid-start";

interface SiteDescriptionProps {
  children: string;
}

export function SiteDescription(props: SiteDescriptionProps) {
  return (
    <>
      <Meta name="description" content={props.children} />
      <Meta property="og:description" content={props.children} />
    </>
  );
}
