import { A } from "solid-start";

import { Anonymous } from "~/components/Anonymous";
import { Button } from "~/components/Button";
import { Layout } from "~/components/Layout";
import { Paper } from "~/components/Paper";
import { SiteTitle } from "~/components/SiteTitle";
import { Typography } from "~/components/Typography";

export const { Page, routeData } = Anonymous(function LoginEmail() {
  return (
    <Layout>
      <SiteTitle>You've got mail!</SiteTitle>
      <Layout.Header>
        <Button as={A} href="/login" variant="outlined" color="white">
          Back
        </Button>
      </Layout.Header>
      <Layout.Main>
        <Typography variant="hero" class="text-center mb-10 dark:text-gray-200">
          You've got mail!
        </Typography>
        <Paper class="flex flex-col max-w-lg mx-auto space-y-6 !p-12">
          <Typography>We've just sent you an email to your address.</Typography>
          <Typography>
            Just click on the link in the email to complete signing into your
            account. You can close this page.
          </Typography>
        </Paper>
      </Layout.Main>
    </Layout>
  );
});

export default Page;
