import { mergeProps } from "solid-js";

import type { DynamicComponentProps, ValidConstructor } from "./utils";
import { classNames } from "./utils";
import { createDynamic } from "./utils";

export function Paper<T extends ValidConstructor = "div">(
  props: DynamicComponentProps<T>
) {
  const paperProps = mergeProps(props, {
    class: classNames(
      props.class,
      "bg-white dark:bg-slate-900 dark:text-gray-200 px-6 py-8 sm:px-10 sm:py-12 lg:px-16 lg:py-20 rounded-xl shadow-xl border border-gray-100 dark:border-slate-800"
    ),
  });
  return createDynamic(() => props.as ?? "div", paperProps);
}
