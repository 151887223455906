import type { ImageAttributes, MarkdownFile } from "@pkg/vite-plugin-markdown";

export interface ExampleMetadata {
  title: string;
  subtitle: string;
  seo?: {
    title: string;
    description: string;
  };
  customer: {
    name: string;
    image: string;
  } & ImageAttributes;
  role: string;
  slug: string;
  benefits?: { title: string; description: string }[];
  tags?: string[];
}

type ExampleMarkdown = MarkdownFile<ExampleMetadata>;

type ExampleMarkdownImport = { default: ExampleMarkdown };

const data = import.meta.glob<boolean, string, ExampleMarkdownImport>(
  "../../data/examples/*.md"
);

export async function getExampleBySlug(slug: string) {
  const exampleLoader = data[`../../data/examples/${slug}.md`];
  if (!exampleLoader) {
    return undefined;
  }
  const example = await exampleLoader().then((m) => m.default);
  return {
    ...example,
    id: slug,
  };
}

export async function getAllExamples() {
  const examples = await Promise.all(
    Object.entries(data).map(([, loader]) => loader().then((m) => m.default))
  );
  return Promise.all(examples.map((contents) => contents.metadata));
}

function extractSlug(key: string) {
  const result = /\.\.\/\.\.\/data\/examples\/(.+)\.md/.exec(key);
  return result?.[1];
}

export function getAllExampleSlugs() {
  return Object.keys(data)
    .map((key) => extractSlug(key))
    .filter((slug) => !!slug);
}

export function getRandomExample() {
  const baseUrl = "/examples";
  const examples = getAllExampleSlugs();
  const random = Math.round(Math.random() * (examples.length - 1));
  const example = examples[random];
  return `${baseUrl}/${example}`;
}

export const examplesSequence = [
  `I am writing to express my interest in the Accountant position at ABC Corporation. As a highly skilled and organized individual with a strong background in accounting, I am confident in my ability to make a positive contribution to your team.
  
I have gained valuable experience through internships at XYZ Inc. and DEF LLC. In these roles, I developed strong skills in financial analysis, budgeting, and tax preparation. I am proficient in a variety of accounting software, including QuickBooks and Xero, and have a strong attention to detail and ability to meet tight deadlines. I also have a Bachelor's degree in Accounting from the University of Washington...`,
  `I am writing to express my interest in the receptionist position at PremierPlaces Hotels. I am confident that my skills and experience make me an excellent fit for this role.
              
I have previously worked as a medical receptionist at HealthFirst Clinic, where I gained valuable experience in customer service and communication within a medical setting. I am skilled in answering phone calls, scheduling appointments, handling patient records, and assisting with billing and insurance. I am also comfortable using a variety of computer programs and systems, including electronic medical records and scheduling software...`,
  `I am writing to apply for the role of Senior Software Engineer at NexusTech. I am passionate about engineering and have developed software for both DataPro Solutions and Hyperion Solutions , as well as for various personal projects. My specialties include Rust, TypeScript,  JavaScript, and I have a high understanding of computer science, mathematics, and information security.
  
My experience in software engineering has also allowed me to design and implement complex systems in Java, as well as developing large scale data systems through both online/streaming and offline/batch fashions...`,
];
