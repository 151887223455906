// @refresh reload
import { Suspense } from "solid-js";
import {
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html,
  Link,
  Meta,
  Routes,
  Scripts,
  Title,
} from "solid-start";

import { ErrorMessage } from "./components/ErrorMessage";
import { GoogleAnalytics } from "./components/GoogleAnalytics";
import { Layout } from "./components/Layout";
import "./root.css";

export default function Root() {
  return (
    <Html lang="en" class="scroll-smooth">
      <Head>
        <Title>coverletter.app</Title>
        <Meta charset="utf-8" />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
        <Link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <Link
          rel="apple-touch-icon"
          href="/apple-touch-icon-57.png"
          sizes="57x57"
        />
        <Link
          rel="apple-touch-icon"
          href="/apple-touch-icon-180.png"
          sizes="180x180"
        />
        <Link
          rel="icon"
          href="/favicon-16.png"
          sizes="16x16"
          type="image/png"
        />
        <Link
          rel="icon"
          href="/favicon-32.png"
          sizes="32x32"
          type="image/png"
        />
        <Meta property="og:image" content="/og_image.png" />
        <Meta property="og:image:width" content="1200" />
        <Meta property="og:image:height" content="630" />
        <GoogleAnalytics />
      </Head>
      <Body class="bg-white dark:bg-slate-900">
        <Suspense>
          <ErrorBoundary
            fallback={(e) => (
              <Layout>
                <Layout.Header />
                <ErrorMessage error={e} />
              </Layout>
            )}
          >
            <Routes>
              <FileRoutes />
            </Routes>
          </ErrorBoundary>
        </Suspense>
        <Scripts />
      </Body>
    </Html>
  );
}
