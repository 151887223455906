import { MetaContext, useHead } from "@solidjs/meta";
import { createUniqueId, useContext } from "solid-js";

interface ScriptProps extends Record<string, unknown> {
  async?: boolean;
  src?: string;
}

export function Script(props: ScriptProps) {
  const id = createUniqueId();
  const c = useContext(MetaContext);
  if (!c) throw new Error("<MetaProvider /> should be in the tree");
  useHead({
    tag: "script",
    props,
    id,
  });
  return null;
}
