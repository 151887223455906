import type { JSX } from "solid-js";

interface ImageBlurSvgOptions {
  widthInt?: number;
  heightInt?: number;
  blurWidth?: number;
  blurHeight?: number;
  blurDataURL: string;
  objectFit?: string;
}

function getImageBlurSvg({
  widthInt,
  heightInt,
  blurWidth,
  blurHeight,
  blurDataURL,
  objectFit,
}: ImageBlurSvgOptions): string {
  const std = blurWidth && blurHeight ? "1" : "20";
  const svgWidth = blurWidth || widthInt;
  const svgHeight = blurHeight || heightInt;
  const feComponentTransfer = blurDataURL.startsWith("data:image/jpeg")
    ? `%3CfeComponentTransfer%3E%3CfeFuncA type='discrete' tableValues='1 1'/%3E%3C/feComponentTransfer%3E%`
    : "";
  if (svgWidth && svgHeight) {
    return `%3Csvg xmlns='http%3A//www.w3.org/2000/svg' viewBox='0 0 ${svgWidth} ${svgHeight}'%3E%3Cfilter id='b' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='${std}'/%3E${feComponentTransfer}%3C/filter%3E%3Cimage preserveAspectRatio='none' filter='url(%23b)' x='0' y='0' height='100%25' width='100%25' href='${blurDataURL}'/%3E%3C/svg%3E`;
  }
  const preserveAspectRatio =
    objectFit === "contain"
      ? "xMidYMid"
      : objectFit === "cover"
      ? "xMidYMid slice"
      : "none";
  return `%3Csvg xmlns='http%3A//www.w3.org/2000/svg'%3E%3Cimage style='filter:blur(20px)' preserveAspectRatio='${preserveAspectRatio}' x='0' y='0' height='100%25' width='100%25' href='${blurDataURL}'/%3E%3C/svg%3E`;
}

// let getOptimizedImageSource = (
//   src: string,
//   width?: number,
//   height?: number,
//   quality = 75
// ) => {
//   const params = new URLSearchParams();
//   params.append("url", src);
//   if (width) {
//     params.append("w", `${width}`);
//   }
//   if (height) {
//     params.append("h", `${height}`);
//   }
//   params.append("q", `${quality}`);
//   return `/_vercel/image?${params.toString()}`;
// };

// if (import.meta.env.DEV) {
//   getOptimizedImageSource = (src: string) => src;
// }

type BaseImgProps = Omit<
  JSX.IntrinsicElements["img"],
  "style" | "width" | "height"
>;

interface ImageProps {
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
  width?: number;
  height?: number;
  style?: JSX.CSSProperties;
  quality?: number;
}

export function Image(props: ImageProps & BaseImgProps) {
  const imgStyle = props.style ?? {};
  const blurStyle: JSX.CSSProperties = props.blurDataURL
    ? {
        "font-size": 0,
        "background-size": "cover",
        "background-position": "50% 50%",
        "background-repeat": "no-repeat",
        "background-image": `url("data:image/svg+xml;charset=utf-8,${getImageBlurSvg(
          {
            blurWidth: props.blurWidth,
            blurHeight: props.blurHeight,
            widthInt: props.width,
            heightInt: props.height,
            blurDataURL: props.blurDataURL,
            objectFit: "cover",
          }
        )}")`,
      }
    : {};
  return (
    <img
      {...props}
      src={props.src}
      width={props.width}
      height={props.height}
      loading="lazy"
      style={{ ...imgStyle, ...blurStyle }}
    />
  );
}
