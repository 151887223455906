import { captureException } from "@sentry/browser";
import { createEffect } from "solid-js";

import { QuestionMarkCircle } from "~/icons/QuestionMarkCircle";

import { Button } from "./Button";
import { SiteTitle } from "./SiteTitle";

interface ErrorMessageProps {
  error: Error;
}

export function ErrorMessage(props: ErrorMessageProps) {
  createEffect(() => {
    console.error(props.error);
    captureException(props.error);
  });
  return (
    <div class="mx-auto max-w-3xl pt-2 sm:pt-16">
      <SiteTitle>An error occured</SiteTitle>
      <h1 class="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl dark:text-gray-200">
        Oops! Something went wrong!
      </h1>
      <p class="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400 sm:text-center">
        There was an issue with our service. Please try again later and contact
        support if the issue persists.
      </p>
      <div class="flex justify-center text-center mt-6 space-x-4">
        <Button class="flex" onClick={() => history.back()}>
          <span class="text-indigo-200 mr-2" aria-hidden="true">
            &larr;
          </span>
          <span>Go back</span>
        </Button>
        <Button
          class="flex items-center"
          variant="outlined"
          color="white"
          as="a"
          href="/contact"
        >
          <QuestionMarkCircle class="w-5 h-5 mr-2" />
          <span>Contact support</span>
        </Button>
      </div>
    </div>
  );
}
