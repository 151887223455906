import { Layout } from "~/components/Layout";
import { Typography } from "~/components/Typography";
import { Fingerprint } from "~/icons/Fingerprint";

import { SiteTitle } from "./SiteTitle";

export function LoadingAuth() {
  return (
    <Layout>
      <SiteTitle>Loading</SiteTitle>
      <Layout.Header />
      <Layout.Main>
        <div class="text-center my-48">
          <Fingerprint class="w-20 h-20 mx-auto text-gray-400" />
          <Typography variant="hero" class="dark:text-gray-200">
            Authenticating...
          </Typography>
        </div>
      </Layout.Main>
    </Layout>
  );
}
