import type { JSX } from "solid-js";
import { Index, mergeProps, splitProps } from "solid-js";

import type { DynamicComponentProps, ValidConstructor } from "./utils";
import { classNames, createDynamic } from "./utils";

export function Skeleton<T extends ValidConstructor = "div">(
  props: DynamicComponentProps<T>
) {
  const [, rest] = splitProps(props, ["as"]);
  const compProps = mergeProps(rest, {
    get class() {
      return classNames(props.class, "animate-pulse");
    },
  });
  return createDynamic(() => props.as ?? "div", compProps);
}

Skeleton.Line = function SkeletonLine<T extends ValidConstructor = "div">(
  props: DynamicComponentProps<T>
) {
  const [, rest] = splitProps(props, ["as"]);
  const compProps = mergeProps(rest, {
    get class() {
      return classNames(
        rest.class,
        "h-3 bg-gray-200 dark:bg-slate-700 rounded"
      );
    },
  });
  return createDynamic(() => props.as ?? "div", compProps);
};

Skeleton.Circle = function SkeletonCircle<T extends ValidConstructor = "div">(
  props: DynamicComponentProps<T>
) {
  const [, rest] = splitProps(props, ["as"]);
  const compProps = mergeProps(rest, {
    get class() {
      return classNames(
        props.class,
        "w-full bg-gray-200 dark:bg-slate-700 rounded-full"
      );
    },
  });
  return createDynamic(() => props.as ?? "div", compProps);
};

Skeleton.Rect = function SkeletonRect<T extends ValidConstructor = "div">(
  props: DynamicComponentProps<T>
) {
  const [, rest] = splitProps(props, ["as"]);
  const compProps = mergeProps(rest, {
    get class() {
      return classNames(
        props.class,
        "w-full bg-gray-200 dark:bg-slate-700 rounded"
      );
    },
  });
  return createDynamic(() => props.as ?? "div", compProps);
};

function createArray(count: number) {
  return Array.from({ length: count });
}

interface SkeletonRepeatProps {
  count: number;
  children: () => JSX.Element;
}

Skeleton.Repeat = function SkeletonRepeat(props: SkeletonRepeatProps) {
  return <Index each={createArray(props.count)}>{props.children}</Index>;
};
