import { mergeProps, splitProps } from "solid-js";

import type { DynamicComponentProps, ValidConstructor } from "./utils";
import { classNames, createDynamic } from "./utils";

type Variant =
  | "hero-lg"
  | "hero"
  | "hero-sm"
  | "body"
  | "body-lg"
  | "body-sm"
  | "body-xs";

const classes = {
  body: "text-sm sm:text-base leading-4 sm:leading-6",
  "body-xs": "text-[0.625rem] sm:text-xs leading-2",
  "body-sm": "text-xs sm:text-sm leading-2 sm:leading-4",
  "body-lg": "text-base sm:text-lg leading-6 sm:leading-8",
  hero: "text-3xl font-bold tracking-tight sm:text-4xl",
  "hero-sm": "text-2xl font-semibold tracking-tight sm:text-3xl",
  "hero-lg": "text-4xl font-bold tracking-tight sm:text-6xl",
};

interface TypographyProps {
  variant?: Variant;
}

export function Typography<T extends ValidConstructor = "p">(
  props: DynamicComponentProps<T> & TypographyProps
) {
  const [, rest] = splitProps(props, ["as", "variant"]);
  const buttonProps = mergeProps(rest, {
    get class() {
      return classNames(props.class, classes[props.variant ?? "body"]);
    },
  });
  return createDynamic(() => props.as ?? "p", buttonProps);
}
