import type { ParentProps } from "solid-js";
import { mergeProps, splitProps } from "solid-js";

import { Spinner } from "./Spinner";
import type { DynamicComponentProps, ValidConstructor } from "./utils";
import { classNames, createDynamic } from "./utils";

type Color = "primary" | "white" | "danger";
type Variant = "solid" | "outlined" | "text";
type Size = "small" | "default";

const defaultClasses = "group inline-block font-semibold transition-colors";

const variantClasses: Record<Variant, Record<Color, string>> = {
  solid: {
    primary:
      "bg-gradient-to-br from-indigo-600 to-indigo-700 text-white shadow-sm ring-1 ring-indigo-600 hover:from-indigo-700 hover:to-indigo-700 hover:ring-indigo-700 disabled:opacity-75 disabled:from-indigo-600 disabled:to-indigo-700",
    white:
      "bg-white text-indigo-600 shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-50 disabled:opacity-75",
    danger: "",
  },
  outlined: {
    primary:
      "text-indigo-600 dark:text-indigo-400 ring-1 ring-indigo-600/40 dark:ring-indigo-400/40 hover:ring-indigo-600/60 dark:hover:ring-indigo-400/60 disabled:opacity-75 disabled:ring-indigo-600/20 dark:disabled:ring-indigo-400/20",
    white:
      "text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20 dark:text-gray-200 dark:ring-gray-200/10 dark:hover:ring-gray-200/20 disabled:opacity-75 disabled:ring-gray-900/20 dark:disabled:ring-gray-200/5",
    danger: "",
  },
  text: {
    primary:
      "text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-50/10",
    white: "text-gray-900 hover:bg-gray-50",
    danger: "text-red-600 hover:bg-red-50",
  },
};

const sizeClasses: Record<Size, { normal: string; iconOnly: string }> = {
  default: {
    normal: "py-1.5 px-4 text-base leading-7 rounded-lg",
    iconOnly: "p-1.5 text-base leading-7 rounded-lg",
  },
  small: {
    normal: "py-1 px-2 text-sm leading-4 rounded",
    iconOnly: "p-1 text-sm leading-4 rounded",
  },
};

interface BaseButtonProps {
  color?: Color;
  variant?: Variant;
  size?: Size;
  iconOnly?: boolean;
  unstyled?: boolean;
}

export type ButtonProps<T extends ValidConstructor = "button"> =
  DynamicComponentProps<T> & BaseButtonProps;

export function Button<T extends ValidConstructor = "button">(
  props: ButtonProps<T>
) {
  const [, rest] = splitProps(props, ["as", "color", "variant"]);
  const buttonProps = mergeProps(rest, {
    get type() {
      return props.type ?? "button";
    },
    get class() {
      return classNames(
        props.class,
        !props.unstyled && defaultClasses,
        !props.unstyled &&
          variantClasses[props.variant ?? "solid"][props.color ?? "primary"],
        !props.unstyled &&
          sizeClasses[props.size ?? "default"][
            props.iconOnly ? "iconOnly" : "normal"
          ]
      );
    },
  });
  return createDynamic(() => props.as ?? "button", buttonProps);
}

interface ButtonArrowProps {
  class?: string;
}

Button.Arrow = function ButtonArrow(props: ButtonArrowProps) {
  return (
    <span
      class={classNames(
        "ml-2 inline-block transition-transform group-hover:translate-x-1 group-disabled:translate-x-0",
        props.class
      )}
      aria-hidden="true"
    >
      &rarr;
    </span>
  );
};

Button.Loading = function ButtonLoading(props: ParentProps) {
  return (
    <>
      <Spinner class="text-white w-5 h-5 inline-block mr-2" /> {props.children}
    </>
  );
};
