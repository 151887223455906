import { For, Show } from "solid-js";
import type { RouteDataArgs } from "solid-start";
import { A, ErrorBoundary, useParams, useRouteData } from "solid-start";
import {
  HttpStatusCode,
  ServerError,
  createServerData$,
} from "solid-start/server";

import { Button } from "~/components/Button";
import { CTA } from "~/components/CTA";
import { Examples } from "~/components/Examples";
import { Image } from "~/components/Image";
import { Layout } from "~/components/Layout";
import { MarkdownContent } from "~/components/Markdown";
import { NotFound } from "~/components/NotFound";
import { Paper } from "~/components/Paper";
import { SiteCanonical } from "~/components/SiteCanonical";
import { SiteDescription } from "~/components/SiteDescription";
import { SiteTitle } from "~/components/SiteTitle";
import { Typography } from "~/components/Typography";
import { Check } from "~/icons/Check";
import { getAllExamples, getExampleBySlug } from "~/server/examples";

type ExampleProps = NonNullable<Awaited<ReturnType<typeof getExampleBySlug>>>;

function Example(props: ExampleProps) {
  return (
    <>
      <SiteCanonical href={`/examples/${props.id}`} />
      <SiteTitle>{`${
        props.metadata.seo?.title ?? props.metadata.title + " example"
      }`}</SiteTitle>
      <SiteDescription>
        {props.metadata.seo?.description ?? props.metadata.subtitle}
      </SiteDescription>
      <div class="mx-auto max-w-7xl text-center">
        <Typography variant="hero" as="h1" class="dark:text-gray-200">
          {props.metadata.title}
        </Typography>
        <Typography
          variant="body-lg"
          class="mt-6 text-gray-600 dark:text-gray-400"
        >
          {props.metadata.subtitle}
        </Typography>
      </div>
      <div class="md:flex md:mx-auto md:max-w-7xl px-0 sm:px-3 lg:px-8 mt-10">
        <div class="flex-shrink-0 mx-auto md:mr-6 mb-8 md:mb-0 md:ml-0 flex justify-center">
          <Image
            src={props.metadata.customer.image}
            class="w-20 h-20 rounded-full"
            alt={`Picture of ${props.metadata.customer.name}`}
            width={props.metadata.customer.width}
            height={props.metadata.customer.height}
            blurDataURL={props.metadata.customer.blurDataURL}
            blurWidth={props.metadata.customer.blurWidth}
            blurHeight={props.metadata.customer.blurHeight}
          />
        </div>
        <Paper
          class="space-y-6 select-none flex-shrink"
          onContextMenu={(ev) => ev.preventDefault()}
        >
          <For each={props.content}>
            {(item) => <MarkdownContent content={item} />}
          </For>
        </Paper>
      </div>
      <Show when={props.metadata.tags}>
        <section class="my-12 sm:my-16 lg:my-20">
          <div class="mx-auto max-w-3xl">
            <Typography
              as="h2"
              variant="hero-sm"
              class="dark:text-gray-200 sm:text-center mb-6"
            >
              Also works for
            </Typography>
            <div class="flex gap-4 items-center justify-center flex-wrap">
              <For each={props.metadata.tags}>
                {(tag) => (
                  <Typography class="inline-flex items-center rounded-md text-indigo-600 dark:text-indigo-400 bg-indigo-200/20 dark:bg-indigo-800/20 ring-1 ring-inset ring-indigo-600/40 dark:ring-indigo-700/40 px-3 py-1.5">
                    {tag}
                  </Typography>
                )}
              </For>
            </div>
          </div>
        </section>
      </Show>
      <Show when={props.metadata.benefits}>
        <section class="my-12 sm:my-16 lg:my-20">
          <div class="mx-auto max-w-3xl">
            <Typography
              as="h2"
              variant="hero-sm"
              class="dark:text-gray-200 sm:text-center mb-6"
            >
              What makes this a good cover letter?
            </Typography>
            <For each={props.metadata.benefits}>
              {(benefit) => (
                <div class="my-6 flex">
                  <Check class="text-green-600 w-6 h-6 flex-shrink-0 mr-2" />
                  <div>
                    <Typography class="dark:text-gray-200 mb-1 font-semibold">
                      {benefit.title}
                    </Typography>
                    <Typography class="text-gray-600 dark:text-gray-400">
                      {benefit.description}
                    </Typography>
                  </div>
                </div>
              )}
            </For>
          </div>
        </section>
      </Show>
    </>
  );
}

export function routeData({ params }: RouteDataArgs) {
  const example = createServerData$(
    async ([, slug]) => {
      const example = await getExampleBySlug(slug);
      if (!example) {
        throw new ServerError("Example not found");
      }
      return example;
    },
    {
      key: () => ["examples", params.example],
      deferStream: true,
      reconcileOptions: {
        key: null,
      },
    }
  );
  const examples = createServerData$(getAllExamples, {
    key: ["examples"],
    deferStream: true,
  });
  return {
    example,
    examples,
  };
}

export default function ExamplePage() {
  const params = useParams();
  const { example, examples } = useRouteData<typeof routeData>();
  return (
    <Layout>
      <Layout.Header>
        <Button as={A} href="/letter/new" class="hidden sm:inline-block">
          Try it yourself for free <Button.Arrow class="text-indigo-200" />
        </Button>
      </Layout.Header>
      <Layout.Main>
        <ErrorBoundary
          fallback={(e) => (
            <Show when={e.message === "Example not found"}>
              <HttpStatusCode code={404} />
              <NotFound />
            </Show>
          )}
        >
          <Show when={example()} keyed>
            {(example) => (
              <Example
                id={example.id}
                content={example.content}
                metadata={example.metadata}
              />
            )}
          </Show>
        </ErrorBoundary>
        <CTA />
        <Show when={examples()} keyed>
          {(examples) => (
            <Examples
              examples={examples.filter((e) => e.slug !== params.example)}
            >
              <Typography variant="hero" class="dark:text-gray-200">
                Check out some of our other examples
              </Typography>
              <Typography
                variant="body-lg"
                class="mt-4 text-gray-600 dark:text-gray-400"
              >
                Personalized cover letters for many different industries and
                roles.
              </Typography>
            </Examples>
          )}
        </Show>
      </Layout.Main>
    </Layout>
  );
}
