import type { FlowProps, JSX } from "solid-js";
import { splitProps } from "solid-js";
import { Show, createContext, useContext } from "solid-js";
import { A } from "solid-start";

import { Button } from "./Button";
import { Typography } from "./Typography";

interface BasePaginationProps {
  total: number;
  page: number;
  perPage: number;
  renderHref: (page: number) => string;
}

type PaginationProps = BasePaginationProps & JSX.IntrinsicElements["div"];

const PaginationContext = createContext<BasePaginationProps>();

export function Pagination(props: PaginationProps) {
  const [contextBag, otherProps] = splitProps(props, [
    "total",
    "page",
    "perPage",
    "renderHref",
  ]);
  return (
    <PaginationContext.Provider value={contextBag}>
      <div {...otherProps}>{otherProps.children}</div>
    </PaginationContext.Provider>
  );
}

Pagination.PreviousPage = function PreviousPage() {
  const ctx = useContext(PaginationContext);
  if (!ctx) {
    throw new Error("Needs to be used in `Pagination`");
  }
  return (
    <Show
      when={ctx.page > 1}
      fallback={
        <Button
          variant="outlined"
          color="white"
          aria-label="Previous page"
          disabled
        >
          &larr;
        </Button>
      }
    >
      <Button
        as={A}
        variant="outlined"
        color="white"
        href={ctx.renderHref(ctx.page - 1)}
        aria-label="Previous page"
      >
        &larr;
      </Button>
    </Show>
  );
};

Pagination.NextPage = function NextPage() {
  const ctx = useContext(PaginationContext);
  if (!ctx) {
    throw new Error("Needs to be used in `Pagination`");
  }
  return (
    <Show
      when={ctx.page * ctx.perPage < ctx.total}
      fallback={
        <Button
          variant="outlined"
          color="white"
          aria-label="Next page"
          disabled
        >
          &rarr;
        </Button>
      }
    >
      <Button
        as={A}
        variant="outlined"
        color="white"
        href={ctx.renderHref(ctx.page + 1)}
        aria-label="Next page"
      >
        &rarr;
      </Button>
    </Show>
  );
};

Pagination.Legend = function PaginationLegend(
  props: FlowProps<
    {},
    (opts: {
      showingFrom: number;
      showingTo: number;
      total: number;
    }) => JSX.Element
  >
) {
  const ctx = useContext(PaginationContext);
  if (!ctx) {
    throw new Error("Needs to be used in `Pagination`");
  }
  const showingFrom = () => (ctx.page - 1) * ctx.perPage + 1;
  const showingTo = () => Math.min(ctx.total, ctx.page * ctx.perPage);
  return (
    <Typography variant="body-sm" class="dark:text-gray-400">
      {props.children({
        showingFrom: showingFrom(),
        showingTo: showingTo(),
        total: ctx.total,
      })}
    </Typography>
  );
};
