import type { ImageAttributes, MarkdownFile } from "@pkg/vite-plugin-markdown";

export interface BlogPostMetadata {
  title: string;
  subtitle: string;
  image: {
    url: string;
    alt: string;
  } & ImageAttributes;
  author: {
    name: string;
    role: string;
  };
  created: string;
  reading_time: number;
  slug: string;
}

type BlogPostMarkdown = MarkdownFile<BlogPostMetadata>;

type BlogPostMarkdownImport = { default: BlogPostMarkdown };

const postsData = import.meta.glob<boolean, string, BlogPostMarkdownImport>(
  "../../data/blog-posts/*.md"
);

export async function getBlogPostBySlug(slug: string) {
  const postLoader = postsData[`../../data/blog-posts/${slug}.md`];
  if (!postLoader) {
    return undefined;
  }
  const post = await postLoader().then((m) => m.default);
  return {
    ...post,
    id: slug,
  };
}

export async function getAllBlogPosts() {
  const postsMarkdown = await Promise.all(
    Object.entries(postsData).map(([, loader]) =>
      loader().then((m) => m.default)
    )
  );
  const posts = await Promise.all(
    postsMarkdown.map(({ metadata }) => metadata)
  );
  return posts.sort((a, b) => Date.parse(b.created) - Date.parse(a.created));
}

export async function paginated<T extends any[]>(
  itemsPromise: Promise<T>,
  page: string,
  perPage: number
) {
  const parsedPage = parseInt(page ?? "1");
  const items = await itemsPromise;
  return {
    page: parsedPage,
    perPage,
    count: items.length,
    items: items.slice(
      (parsedPage - 1) * perPage,
      Math.min(items.length, parsedPage * perPage)
    ),
  };
}
