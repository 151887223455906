import type { Component } from "solid-js";
import { Show, Suspense } from "solid-js";
import { Navigate, createRouteData, useRouteData } from "solid-start";
import server$, { redirect } from "solid-start/server";

import { getSession } from "~/server/auth";

import { LoadingAuth } from "./LoadingAuth";

const getUser$ = server$(async () => {
  const session = await getSession(server$.request);
  if (session) {
    throw redirect("/profile");
  }
  return {};
});

export function Anonymous(Comp: Component) {
  const routeData = () => {
    return createRouteData(() => getUser$(), {
      key: () => ["anonymous"],
      deferStream: true,
    });
  };

  return {
    routeData,
    Page: () => {
      const noSession = useRouteData<typeof routeData>();
      return (
        <Suspense fallback={<LoadingAuth />}>
          <Show when={noSession()} keyed>
            {(sess) => (
              <Show
                when={!(sess instanceof Response)}
                fallback={<Navigate href="/profile" />}
              >
                <Comp {...sess} />
              </Show>
            )}
          </Show>
        </Suspense>
      );
    },
  };
}
