import { Link, Meta } from "solid-start";

interface SiteCanonicalProps {
  href: string;
  type?: string;
}

export function SiteCanonical(props: SiteCanonicalProps) {
  return (
    <>
      <Link rel="canonical" href={`https://coverletter.app${props.href}`} />
      <Meta property="og:type" content={props.type ?? "website"} />
      <Meta
        property="og:url"
        content={`https://coverletter.app${props.href}`}
      />
    </>
  );
}
