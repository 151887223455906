import { Transition } from "solid-headless";
import type { JSX, ParentProps } from "solid-js";
import { Show, createEffect, createSignal } from "solid-js";

import { XMark } from "~/icons/XMark";

import { classNames } from "./utils";

interface ToastProps {
  show: boolean;
  dismissable?: boolean;
}

export function Toast(
  props: ParentProps<ToastProps & JSX.IntrinsicElements["div"]>
) {
  const [_show, setShow] = createSignal(props.show);
  createEffect(() => {
    setShow(props.show);
  });
  return (
    <Transition
      show={_show()}
      class={classNames(
        props.class,
        "fixed top-8 left-1/2 -translate-x-1/2 shadow-xl px-4 py-3 rounded-lg flex items-center text-sm font-semibold z-10"
      )}
      enter="transition duration-200"
      enterFrom="opacity-0 -translate-y-1 scale-50"
      enterTo="opacity-100 translate-y-0 scale-100"
      leave="transition duration-150"
      leaveFrom="opacity-100 translate-y-0 scale-100"
      leaveTo="opacity-0 -translate-y-1 scale-50"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {props.children}
      <Show when={props.dismissable}>
        <button
          onClick={() => setShow(false)}
          class="ml-2 hover:bg-black/10 p-0.5 rounded"
          aria-label="Dismiss"
        >
          <XMark class="w-4 h-4" />
        </button>
      </Show>
    </Transition>
  );
}
