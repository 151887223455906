import { mergeProps } from "solid-js";
import { A } from "solid-start";

import { Button } from "./Button";
import { Typography } from "./Typography";
import type { DynamicComponentProps, ValidConstructor } from "./utils";
import { classNames, createDynamic } from "./utils";

export function CTA<T extends ValidConstructor = "section">(
  props: DynamicComponentProps<T>
) {
  const ctaProps = mergeProps(props, {
    class: classNames(
      props.class,
      "bg-white dark:bg-slate-900 my-24 sm:my-32 lg:my-40"
    ),
    get children() {
      return (
        <div class="mx-auto max-w-7xl">
          <div class="bg-gradient-to-br from-indigo-700 to-indigo-800 rounded-3xl px-12 py-16 relative shadow-xl text-white text-center">
            <Typography variant="hero">
              Create your own personalised cover letter
            </Typography>
            <Typography variant="body-lg" class="mt-4 text-indigo-100">
              Sign up now and get personalized, professional, and customizable
              AI-generated cover letters!
            </Typography>
            <Button as={A} href="/letter/new" color="white" class="mt-6">
              Try it for free <Button.Arrow />
            </Button>
          </div>
        </div>
      );
    },
  });
  return createDynamic(() => props.as ?? "section", ctaProps as any);
}
