import type { ParentProps } from "solid-js";
import { A, Link } from "solid-start";

import { Banner } from "./Banner";
import { Logo } from "./Logo";
import { Typography } from "./Typography";

function BackgroundAnimation() {
  return (
    <div class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu blur-3xl sm:top-[-20rem]">
      <svg
        class="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem] w-full"
        viewBox="0 0 1155 678"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
          fill-opacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        >
          <animate
            dur="15s"
            repeatCount="indefinite"
            attributeName="d"
            calcMode="spline"
            fill="freeze"
            keySplines="0.4 0 0.2 1; 0.4 0 0.2 1; 0.4 0 0.2 1"
            values="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z;M171 196.5L347 847.5L0.5 429.5L171 196.5L591 113C592.307 245.337 691.35 301.91 856 100.5C1061.81 -151.263 1075.03 133.935 1224 269.5C1343.18 377.951 946.5 904.5 897.55 493.553L761.5 376L487.5 595.974L171 196.5Z;M301.5 320.312L129 558L0.5 0.5L301.5 320.312L327 61.5C328.307 193.837 541.35 699.91 706 498.5C911.812 246.737 1205.53 257.747 1354.5 393.312C1473.68 501.763 1080.45 856.947 1031.5 446L706 310.5L367 878L301.5 320.312Z;M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
        </path>
        <defs>
          <linearGradient
            id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9089FC">
              <animate
                attributeName="stop-color"
                values="#9089FC;#FCB989;#9089FC"
                dur="10s"
                repeatCount="indefinite"
                fill="freeze"
              />
            </stop>
            <stop offset="1" stop-color="#FF80B5">
              <animate
                attributeName="stop-color"
                values="#FF80B5;#ED80FF;#FF80B5"
                dur="10s"
                repeatCount="indefinite"
                fill="freeze"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

function FooterMenuSection(props: ParentProps) {
  return <div>{props.children}</div>;
}

function FooterMenuSectionTitle(props: ParentProps) {
  return (
    <Typography class="font-semibold text-gray-800 dark:text-gray-300">
      {props.children}
    </Typography>
  );
}

function FooterMenu(props: ParentProps) {
  return (
    <ul class="mt-3 space-y-2 text-gray-500 dark:text-gray-400">
      {props.children}
    </ul>
  );
}

interface FooterMenuItemProps {
  href: string;
}

function FooterMenuItem(props: ParentProps<FooterMenuItemProps>) {
  return (
    <li>
      <A
        href={props.href}
        class="hover:text-gray-800 dark:hover:text-gray-300 transition-colors"
      >
        {props.children}
      </A>
    </li>
  );
}

export function Layout(props: ParentProps) {
  return (
    <div class="isolate bg-white dark:bg-slate-900">
      <BackgroundAnimation />
      {props.children}
      <footer class="px-6 lg:px-8">
        <div class="lg:flex lg:justify-between mx-auto max-w-7xl space-y-16 lg:space-y-0 lg:space-x-24">
          <div>
            <A
              href="/"
              class="flex items-center space-x-2 text-xl font-semibold text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-400 group"
            >
              <span>
                coverletter
                <span class="bg-gray-500 dark:bg-gray-400 group-hover:bg-gray-800 dark:group-hover:bg-gray-400 text-white dark:text-slate-900 rounded px-1 py-0.5 ml-1">
                  app
                </span>
              </span>
            </A>
            <Typography
              variant="body-lg"
              class="text-gray-500 dark:text-gray-400 mt-6 max-w-xl"
            >
              Our mission is to help job seekers stand out and increase their
              chances of getting hired with personalized, professional cover
              letters from our advanced AI technology.
            </Typography>
          </div>
          <nav class="flex flex-wrap gap-12 sm:gap-16" aria-label="Footer">
            <FooterMenuSection>
              <FooterMenuSectionTitle>Examples</FooterMenuSectionTitle>
              <div class="flex space-x-12">
                <FooterMenu>
                  <FooterMenuItem href="/examples/software-engineer-cover-letter">
                    Software engineer
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/accounting-cover-letter">
                    Accounting
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/finance-cover-letter">
                    Finance
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/nursing-cover-letter">
                    Nursing
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/retail-cover-letter">
                    Retail
                  </FooterMenuItem>
                </FooterMenu>
                <FooterMenu>
                  <FooterMenuItem href="/examples/law-cover-letter">
                    Law
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/hospitality-cover-letter">
                    Hospitality
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/consulting-cover-letter">
                    Consulting
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/graphic-designer-cover-letter">
                    Graphic designer
                  </FooterMenuItem>
                  <FooterMenuItem href="/examples/data-analyst-cover-letter">
                    Data analyst
                  </FooterMenuItem>
                </FooterMenu>
              </div>
            </FooterMenuSection>
            <FooterMenuSection>
              <FooterMenuSectionTitle>Company</FooterMenuSectionTitle>
              <FooterMenu>
                <FooterMenuItem href="/contact">Contact us</FooterMenuItem>
                <FooterMenuItem href="/blog">Blog</FooterMenuItem>
              </FooterMenu>
            </FooterMenuSection>
            <FooterMenuSection>
              <FooterMenuSectionTitle>Legal</FooterMenuSectionTitle>
              <FooterMenu>
                <FooterMenuItem href="/terms">Terms</FooterMenuItem>
                <FooterMenuItem href="/privacy">Privacy</FooterMenuItem>
              </FooterMenu>
            </FooterMenuSection>
          </nav>
        </div>
        <div class="mx-auto max-w-7xl border-t border-gray-200 dark:border-slate-600 py-8 mt-16 flex flex-col space-y-8">
          <Typography
            variant="body-sm"
            class="text-gray-500 dark:text-gray-400"
          >
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://relayforms.com" target="_blank">
              RelayForms Pty Ltd
            </a>
            . All rights reserved
          </Typography>
        </div>
      </footer>
    </div>
  );
}

Layout.Header = function Header(props: ParentProps) {
  return (
    <header class="py-6 px-6 lg:px-8 sm:mb-6">
      <Banner>
        <strong>We are closing!</strong> We regret to inform you that
        coverletter.app is closing by Jan 1, 2024.{" "}
        <A href="/blog/we-are-closing" class="underline">
          Learn more
        </A>
      </Banner>
      <Banner.Spacer />
      <div class="flex justify-between items-center max-w-7xl mx-auto">
        <Logo />
        <div class="flex w-full ml-10 justify-end items-center space-x-4">
          {props.children}
        </div>
      </div>
    </header>
  );
};

Layout.Main = function Main(props: ParentProps) {
  return <main class="mb-36 px-6 lg:px-8">{props.children}</main>;
};
