import type { JSX } from "solid-js";

export function Fingerprint(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg viewBox="0 0 34 34" {...props}>
      <g
        transform="translate(1 1)"
        stroke="currentColor"
        fill="none"
        fill-rule="evenodd"
      >
        <g stroke-linecap="round">
          <path
            class="animate-auth"
            stroke-dasharray="12.15426254272461"
            d="M10.144 7.974s2.165-1.468 5.916-1.468c3.752 0 5.722 1.468 5.722 1.468"
          />
          <path
            class="animate-auth"
            stroke-dasharray="19.79115867614746"
            d="M7.466 13.276s2.724-4.517 8.63-4.311c5.904.206 8.379 4.207 8.379 4.207"
          />
          <path
            class="animate-auth"
            stroke-dasharray="53.00725173950195"
            d="M9 22.469s-.874-1.538-.73-4.496c.189-3.833 3.926-6.671 7.783-6.671s5.947 2.527 6.845 4.03c.694 1.163 1.862 5.104-1.275 5.689-2.21.411-3.032-1.53-3.032-2.035 0-1.013-.486-2.752-2.538-2.752-2.053 0-2.94 1.633-2.594 3.562.194 1.082 1.14 4.574 5.558 5.765"
          />
          <path
            class="animate-auth"
            stroke-dasharray="23.70177841186523"
            d="M14.02 25.666s-3.137-3.097-3.137-6.706c0-3.608 2.716-5.154 5.125-5.154 2.408 0 5.172 1.751 5.172 4.954"
          />
          <path
            class="animate-auth"
            stroke-dasharray="8.837481498718262"
            d="M15.955 18.67s.094 5.459 6.114 4.691"
          />
        </g>
      </g>
    </svg>
  );
}
