import type { JSX } from "solid-js";
import { splitProps } from "solid-js";

import { Typography } from "./Typography";
import { classNames } from "./utils";

interface InputProps {
  error?: boolean;
}

export function Input(props: InputProps & JSX.IntrinsicElements["input"]) {
  const [, inputProps] = splitProps(props, ["error"]);
  return (
    <input
      {...inputProps}
      class={classNames(
        props.class,
        "w-full bg-gray-50 dark:bg-slate-800 rounded-lg px-4 py-3 ring-1 ring-gray-200 dark:ring-slate-700 hover:ring-indigo-400 disabled:opacity-75 transition-all"
      )}
      classList={{
        "!ring-red-200 !bg-red-50 text-red-600": !!props.error,
      }}
    />
  );
}

export function Textarea(
  props: InputProps & JSX.IntrinsicElements["textarea"]
) {
  const [, inputProps] = splitProps(props, ["error"]);
  return (
    <textarea
      {...inputProps}
      class={classNames(
        props.class,
        "w-full bg-gray-50 dark:bg-slate-800 rounded-lg px-4 py-3 ring-1 ring-gray-200 dark:ring-slate-700 hover:ring-indigo-400 disabled:opacity-75 transition-all"
      )}
      classList={{
        "!ring-red-200 !bg-red-50 text-red-600": !!props.error,
      }}
    />
  );
}

Input.Label = function InputLabel(
  props: InputProps & JSX.IntrinsicElements["label"]
) {
  return (
    <Typography
      as="label"
      {...props}
      class={classNames(props.class, "flex font-semibold mb-2")}
      classList={{
        "text-red-600": !!props.error,
      }}
    />
  );
};

Input.Hint = function InputHint(props: JSX.IntrinsicElements["span"]) {
  return (
    <Typography
      as="span"
      variant="body-sm"
      role="presentation"
      {...props}
      class={
        (classNames(props.class), "ml-auto mt-auto font-normal text-gray-400")
      }
    />
  );
};

Input.Field = function InputField(props: JSX.IntrinsicElements["div"]) {
  return <div {...props} />;
};
