import { signIn } from "@auth/solid-start/client";
import { Show, createSignal } from "solid-js";
import { A, createRouteAction } from "solid-start";

import { Anonymous } from "~/components/Anonymous";
import { Button } from "~/components/Button";
import { Input } from "~/components/Input";
import { Layout } from "~/components/Layout";
import { Paper } from "~/components/Paper";
import { SiteCanonical } from "~/components/SiteCanonical";
import { SiteDescription } from "~/components/SiteDescription";
import { SiteTitle } from "~/components/SiteTitle";
import { Toast } from "~/components/Toast";
import { Typography } from "~/components/Typography";

interface LoginSuccessProps {
  email?: string;
}

function LoginSuccess(props: LoginSuccessProps) {
  return (
    <>
      <Typography variant="hero" class="text-center mb-10">
        You've got mail!
      </Typography>
      <div class="flex flex-col max-w-lg mx-auto space-y-6 bg-white p-12 rounded-xl shadow-xl">
        <Typography>
          We've just sent you an email to your address ({props.email}).
        </Typography>
        <Typography>
          Just click on the link in the email to complete signing into your
          account. You can close this page.
        </Typography>
      </div>
    </>
  );
}

export const { Page, routeData } = Anonymous(function Login() {
  const [showNotice, setShowNotice] = createSignal(false);
  const [auth, { Form }] = createRouteAction(async (formData: FormData) => {
    const email = formData.get("email") as string | null;
    if (!email) {
      throw new Error("Please enter a valid email address");
    }
    // await signIn("email", { email, callbackUrl: "/profile" });
    setShowNotice(true);
    return false;
  });
  const handleGoogleLogin = async () => {
    setShowNotice(true);
    // await signIn("google", { callbackUrl: "/profile" });
  };
  const handleLinkedInLogin = async () => {
    setShowNotice(true);
    // await signIn("linkedin", { callbackUrl: "/profile" });
  };
  return (
    <Layout>
      <Toast show={!!showNotice()} dismissable class="bg-red-500 text-white">
        Sign in has been disabled, as coverletter.app is shutting down.
      </Toast>
      <SiteCanonical href="/login" />
      <SiteTitle>Sign in to your account</SiteTitle>
      <SiteDescription>
        Sign in to your coverletter.app account to create and edit your
        AI-generated cover letters. Log in or join now to create tailored,
        expert cover letters for job applications.
      </SiteDescription>
      <Layout.Header>
        <Button as={A} href="/" variant="outlined" color="white">
          Back
        </Button>
      </Layout.Header>
      <Layout.Main>
        <Show
          when={!auth.result}
          fallback={
            <LoginSuccess
              email={auth.input?.get("email") as string | undefined}
            />
          }
        >
          <Typography
            variant="hero"
            class="text-center mb-10 dark:text-gray-200"
            as="h1"
          >
            Sign in to your account
          </Typography>
          <Paper class="flex flex-col max-w-md mx-auto space-y-8 lg:!px-12 lg:!py-16">
            <div class="flex flex-col space-y-4">
              <Button
                onClick={handleGoogleLogin}
                color="white"
                class="!bg-white dark:!bg-slate-800 hover:!bg-gray-200 dark:hover:!bg-slate-700 !text-black dark:!text-gray-200 !ring-gray-200 dark:!ring-slate-700 flex items-center justify-center"
              >
                <svg class="w-8 h-8 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                  <path d="M1 1h22v22H1z" fill="none" />
                </svg>
                Continue with Google
              </Button>
              <Button
                onClick={handleLinkedInLogin}
                color="white"
                class="!bg-[#0a66c2] hover:!bg-[#0959aa] !text-white !ring-[#0959aa] flex items-center justify-center"
              >
                <svg class="w-8 h-8 mr-2" viewBox="0 0 76.624 65.326">
                  <path
                    d="M958.98,112.559h-9.6V97.525c0-3.585-.064-8.2-4.993-8.2-5,0-5.765,3.906-5.765,7.939v15.294h-9.6V81.642h9.216v4.225h.129a10.1,10.1,0,0,1,9.093-4.994c9.73,0,11.524,6.4,11.524,14.726ZM918.19,77.416a5.571,5.571,0,1,1,5.57-5.572,5.571,5.571,0,0,1-5.57,5.572m4.8,35.143h-9.61V81.642h9.61Zm40.776-55.2h-55.21a4.728,4.728,0,0,0-4.781,4.67v55.439a4.731,4.731,0,0,0,4.781,4.675h55.21a4.741,4.741,0,0,0,4.8-4.675V62.025a4.738,4.738,0,0,0-4.8-4.67"
                    transform="translate(-903.776 -57.355)"
                    fill="#ffffff"
                  />
                </svg>
                Continue with LinkedIn
              </Button>
            </div>
            <Typography
              variant="body-sm"
              class="text-center text-gray-500 dark:text-gray-400"
            >
              or sign in with your email
            </Typography>
            <Form class="flex flex-col">
              <Input.Field>
                <Input.Label for="email" error={auth.error}>
                  Email
                </Input.Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email..."
                  required
                  error={auth.error}
                  disabled={auth.pending}
                />
              </Input.Field>
              <Show when={auth.error}>
                <Typography variant="body-sm" class="text-red-500 mt-1">
                  {auth.error.message}
                </Typography>
              </Show>
              <Button type="submit" class="mt-6" disabled={auth.pending}>
                <Show
                  when={!auth.pending}
                  fallback={<Button.Loading>Sending email...</Button.Loading>}
                >
                  Sign in with link
                </Show>
              </Button>
            </Form>
          </Paper>
          <Typography variant="body-sm" class="text-gray-400 mt-6 text-center">
            By continuing, you agree to RelayForms's{" "}
            <A href="/terms" class="text-indigo-400 hover:underline">
              terms
            </A>{" "}
            and{" "}
            <A href="/privacy" class="text-indigo-400 hover:underline">
              privacy policy
            </A>
          </Typography>
        </Show>
      </Layout.Main>
    </Layout>
  );
});

export default Page;
