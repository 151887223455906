import { For, Show } from "solid-js";
import type { RouteDataArgs } from "solid-start";
import ErrorBoundary, { A, createRouteData, useRouteData } from "solid-start";
import { HttpStatusCode, ServerError } from "solid-start/server";

import { Button } from "~/components/Button";
import { CTA } from "~/components/CTA";
import { Examples } from "~/components/Examples";
import { Layout } from "~/components/Layout";
import { MarkdownContent } from "~/components/Markdown";
import { NotFound } from "~/components/NotFound";
import { Paper } from "~/components/Paper";
import { SiteCanonical } from "~/components/SiteCanonical";
import { SiteDescription } from "~/components/SiteDescription";
import { SiteTitle } from "~/components/SiteTitle";
import { Typography } from "~/components/Typography";
import { getAllBlogPosts, getBlogPostBySlug } from "~/server/blogPosts";
import { getAllExamples } from "~/server/examples";

import { BlogPostPreview } from "./(blog)";

export function routeData({ params }: RouteDataArgs) {
  const blogPost = createRouteData(
    async ([, slug]) => {
      const post = await getBlogPostBySlug(slug);
      if (!post) {
        throw new ServerError("Example not found");
      }
      return post;
    },
    {
      key: () => ["posts", params.post],
      reconcileOptions: {
        key: null,
      },
      deferStream: true,
    }
  );
  const relatedPosts = createRouteData(
    ([, slug]) =>
      getAllBlogPosts().then((posts) =>
        posts.filter((post) => post.slug !== slug).slice(0, 3)
      ),
    {
      key: () => ["related_posts", params.post],
      reconcileOptions: {
        key: null,
      },
      deferStream: true,
    }
  );
  const examples = createRouteData(() => getAllExamples(), {
    deferStream: true,
  });
  return {
    blogPost,
    relatedPosts,
    examples,
  };
}

type BlogPostProps = NonNullable<Awaited<ReturnType<typeof getBlogPostBySlug>>>;

function BlogPost(props: BlogPostProps) {
  const creationDate = new Date(props.metadata.created);
  return (
    <article class="mx-auto max-w-5xl">
      <SiteCanonical href={`/blog/${props.metadata.slug}`} type="article" />
      <SiteTitle>{props.metadata.title}</SiteTitle>
      <SiteDescription>{props.metadata.subtitle}</SiteDescription>
      <header>
        <Typography
          as="h1"
          variant="hero"
          class="sm:text-center dark:text-gray-200"
        >
          {props.metadata.title}
        </Typography>
        <Typography
          variant="body-lg"
          class="text-gray-600 dark:text-gray-400 sm:text-center mt-4"
        >
          {props.metadata.subtitle}
        </Typography>
        <div class="mt-4 flex sm:justify-center items-center text-gray-500 dark:text-gray-400">
          <Typography
            as="time"
            variant="body-sm"
            datetime={creationDate.toISOString()}
          >
            {Intl.DateTimeFormat("en-US", {
              dateStyle: "medium",
            }).format(creationDate)}
          </Typography>
          <span class="mx-2">•</span>
          <Typography variant="body-sm">
            {props.metadata.reading_time} min read
          </Typography>
        </div>
      </header>
      <Paper class="space-y-5 lg:space-y-8 space-y-6 mt-6 sm:mt-10">
        <For each={props.content}>
          {(item) => <MarkdownContent content={item} />}
        </For>
        <footer class="text-gray-600 dark:text-gray-400 italic">
          Written by {props.metadata.author.name} - {props.metadata.author.role}
        </footer>
      </Paper>
    </article>
  );
}

export default function BlogPostPage() {
  const { blogPost, relatedPosts, examples } = useRouteData<typeof routeData>();
  return (
    <Layout>
      <Layout.Header>
        <Button
          as={A}
          href="/blog"
          variant="outlined"
          color="white"
          class="hidden sm:inline-block"
        >
          More from our blog <Button.Arrow class="text-gray-500" />
        </Button>
      </Layout.Header>
      <Layout.Main>
        <ErrorBoundary
          fallback={(e) => (
            <Show when={e.message === "Example not found"}>
              <HttpStatusCode code={404} />
              <NotFound />
            </Show>
          )}
        >
          <Show when={blogPost()} keyed>
            {(blogPost) => <BlogPost {...blogPost} />}
          </Show>
        </ErrorBoundary>
        <aside class="mx-auto my-24 sm:my-32 lg:my-40">
          <Typography
            variant="hero-sm"
            as="h2"
            class="text-center mb-12 dark:text-gray-200"
          >
            More from our blog
          </Typography>
          <div class="space-y-10 md:space-y-0 md:flex md:flex-wrap md:gap-12 md:items-stretch md:justify-center">
            <Show when={relatedPosts()} keyed>
              {(posts) => (
                <For each={posts}>
                  {(post) => <BlogPostPreview {...post} />}
                </For>
              )}
            </Show>
          </div>
        </aside>
        <CTA />
        <Show when={examples()} keyed>
          {(examples) => (
            <Examples examples={examples}>
              <Typography variant="hero" class="dark:text-gray-200">
                Check out some of our examples
              </Typography>
              <Typography
                variant="body-lg"
                class="mt-4 text-gray-600 dark:text-gray-400"
              >
                Personalized cover letters for many different industries and
                roles.
              </Typography>
            </Examples>
          )}
        </Show>
      </Layout.Main>
    </Layout>
  );
}
