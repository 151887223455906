import { For, Show, Suspense } from "solid-js";
import type { RouteDataArgs } from "solid-start";
import { useParams } from "solid-start";
import { A, createRouteData, useRouteData } from "solid-start";

import { Button } from "~/components/Button";
import { CTA } from "~/components/CTA";
import { Image } from "~/components/Image";
import { Layout } from "~/components/Layout";
import { Pagination } from "~/components/Pagination";
import { Paper } from "~/components/Paper";
import { SiteCanonical } from "~/components/SiteCanonical";
import { SiteDescription } from "~/components/SiteDescription";
import { SiteTitle } from "~/components/SiteTitle";
import { Skeleton } from "~/components/Skeleton";
import { Typography } from "~/components/Typography";
import type { BlogPostMetadata } from "~/server/blogPosts";
import { getAllBlogPosts, paginated } from "~/server/blogPosts";

export const blogPostsPerPage = 9;

export function BlogPostPreview(props: BlogPostMetadata) {
  return (
    <A href={`/blog/${props.slug}`} class="block md:max-w-sm group">
      <Paper class="!p-0 overflow-hidden group-hover:-translate-y-1 group-hover:shadow-2xl transition-all h-full flex flex-col">
        <Image
          src={props.image.url}
          alt={props.image.alt}
          blurDataURL={props.image.blurDataURL}
          blurWidth={props.image.blurWidth}
          blurHeight={props.image.blurHeight}
          class="w-full h-48 object-cover"
        />
        <div class="p-6 flex flex-col items-between justify-end grow">
          <Typography variant="body-lg" as="span" class="block !leading-6">
            {props.title}
          </Typography>
          <Typography
            variant="body-sm"
            as="span"
            class="text-gray-600 dark:text-gray-400 block mt-2"
          >
            {props.subtitle}
          </Typography>
          <div class="flex justify-between items-center mt-auto pt-4">
            <Typography
              as="span"
              variant="body-xs"
              class="text-gray-500 dark:text-gray-400"
            >
              By {props.author.name}
            </Typography>
            <Button variant="text" color="primary" as="span">
              Read more
            </Button>
          </div>
        </div>
      </Paper>
    </A>
  );
}

function PreviewSkeleton() {
  return (
    <div class="md:max-w-sm">
      <Paper class="!p-0 overflow-hidden w-full">
        <Skeleton class="w-full h-48">
          <Skeleton.Rect class="w-full h-48" />
        </Skeleton>
        <div class="p-6">
          <Skeleton class="mb-4">
            <Skeleton.Line class="w-[340px]" />
          </Skeleton>
          <Skeleton class="space-y-2 mb-6">
            <Skeleton.Line class="w-full" />
            <Skeleton.Line class="w-56" />
          </Skeleton>
          <Skeleton>
            <Skeleton.Line class="w-36" />
          </Skeleton>
        </div>
      </Paper>
    </div>
  );
}

export function routeData({ params }: RouteDataArgs) {
  return createRouteData(
    ([, page]) => paginated(getAllBlogPosts(), page, blogPostsPerPage),
    { key: () => ["posts", params.page], reconcileOptions: { key: null } }
  );
}

export default function Blog() {
  const params = useParams();
  const resolvedPage = () => (params.page ? parseInt(params.page) : 1);
  const posts = useRouteData<typeof routeData>();
  return (
    <Layout>
      <Show
        when={resolvedPage() > 1}
        fallback={
          <>
            <SiteTitle>The coverletter.app career advice blog</SiteTitle>
            <SiteDescription>
              Explore coverletter.app's blog for valuable insights, expert
              advice, and practical tips to assist job seekers. Dive into topics
              from crafting standout cover letters to mastering the job market -
              your go-to resource for career success.
            </SiteDescription>
            <SiteCanonical href="/blog" />
          </>
        }
      >
        <SiteTitle>
          {`Page ${params.page} of the coverletter.app career advice blog`}
        </SiteTitle>
        <SiteDescription>
          Explore coverletter.app's blog for valuable insights, expert advice,
          and practical tips to assist job seekers. Dive into topics from
          crafting standout cover letters to mastering the job market - your
          go-to resource for career success.
        </SiteDescription>
        <SiteCanonical href={`/blog/page/${params.page}`} />
      </Show>
      <Layout.Header>
        <Button
          as={A}
          href="/letter/new"
          variant="outlined"
          color="white"
          class="hidden sm:inline-block"
        >
          Try it for free now <Button.Arrow class="text-gray-500" />
        </Button>
      </Layout.Header>
      <Layout.Main>
        <div class="mx-auto max-w-3xl sm:text-center dark:text-gray-200">
          <Typography as="h1" variant="hero">
            From our Blog
          </Typography>
          <Typography
            variant="body-lg"
            class="text-gray-600 dark:text-gray-400 mt-6"
          >
            Discover recent product updates, career advice and other interesting
            thoughts from the coverletter.app team and our expert guest authors.
          </Typography>
        </div>
        <div class="space-y-10 md:space-y-0 md:flex md:flex-wrap md:gap-12 md:items-stretch md:justify-center mt-12">
          <Suspense
            fallback={
              <Skeleton.Repeat count={6}>
                {() => <PreviewSkeleton />}
              </Skeleton.Repeat>
            }
          >
            <Show when={posts()} keyed>
              {({ items }) => (
                <For each={items}>
                  {(post) => <BlogPostPreview {...post} />}
                </For>
              )}
            </Show>
          </Suspense>
        </div>
        <Show when={posts()} keyed>
          {(result) => (
            <Pagination
              page={result.page}
              perPage={result.perPage}
              total={result.count}
              renderHref={(page) => (page > 1 ? `/blog/page/${page}` : "/blog")}
              class="flex justify-center items-center space-x-4 mt-16"
            >
              <Pagination.PreviousPage />
              <Pagination.Legend>
                {({ showingFrom, showingTo, total }) =>
                  `Showing ${showingFrom}-${showingTo} of ${total} posts`
                }
              </Pagination.Legend>
              <Pagination.NextPage />
            </Pagination>
          )}
        </Show>
        <CTA />
      </Layout.Main>
    </Layout>
  );
}
