import type { RouteDataArgs } from "solid-start";
import { createRouteData } from "solid-start";

import { getAllBlogPosts, paginated } from "~/server/blogPosts";

import Blog, { blogPostsPerPage } from "../(blog)";

export function routeData({ params }: RouteDataArgs) {
  return createRouteData(
    ([, page]) => paginated(getAllBlogPosts(), page, blogPostsPerPage),
    { key: () => ["posts", params.page], reconcileOptions: { key: null } }
  );
}

export default Blog;
