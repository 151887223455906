import { A } from "solid-start";

export function Logo() {
  return (
    <div class="flex items-center space-x-2 text-xl font-semibold">
      <A
        href="/"
        class="group hover:text-indigo-500 dark:text-white transition-colors"
      >
        coverletter
        <span class="bg-indigo-600 text-white rounded px-1 leading-none ml-1 pb-0.5 group-hover:bg-indigo-700 transition-colors">
          app
        </span>
        <span class="block text-gray-500 dark:text-gray-200 text-xs font-thin -mt-1">
          by RelayForms
        </span>
      </A>
    </div>
  );
}
