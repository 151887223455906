import type { ParentProps } from "solid-js";

export function Banner(props: ParentProps) {
  return (
    <div class="absolute left-0 right-0 top-0 bg-red-700 text-red-100 px-6 lg:px-8">
      <div class="mx-auto max-w-7xl px-6 lg:px-8 py-1 sm:py-2 text-xs sm:text-sm text-center">
        {props.children}
      </div>
    </div>
  );
}

Banner.Spacer = function BannerSpacer() {
  return <div role="presentation" class="h-9" />;
};
