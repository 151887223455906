import { createComponent, mergeProps } from "solid-js";
import { Dynamic } from "solid-js/web";

import type { DynamicProps, ValidConstructor } from "./dynamicTypes";

export function createDynamic<T extends ValidConstructor>(
  source: () => T,
  props: DynamicProps<T>
) {
  return createComponent(
    Dynamic,
    mergeProps(
      {
        get component() {
          return source();
        },
      },
      props
    )
  );
}
