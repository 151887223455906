import { Script } from "./Script";

const isDev = import.meta.env.MODE === "development";

export function GoogleAnalytics() {
  if (isDev) {
    return (
      <Script type="text/javascript">
        {`
function gtag(){console.log(arguments);}
gtag('js', new Date());

gtag('config', 'G-S6P7CFE689');
gtag('config', 'UA-252493390-1');`}
      </Script>
    );
  }
  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-S6P7CFE689"
      >{` `}</Script>
      <Script type="text/javascript">
        {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-S6P7CFE689');
  gtag('config', 'UA-252493390-1');`}
      </Script>
    </>
  );
}
